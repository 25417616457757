<template>
  <div>
    <header>
      <!-- <div style="width: 20px;"></div> -->
      <div class="title" style="width: 220px;">
        登录人：{{personalName}}
      </div>
      <div>
        <button class="back-login" @click="outLogin">退出登录</button>
      </div>
<!--       <h1 class="logo">-->
<!--        <a href="javascript:void(0);">-->
<!--          {{ userInfo.avatar }}-->
<!--          <img-->
<!--            style="border-radius: 50%"-->
<!--            src="/img/wxshare/logo-weiyegg.png"-->
<!--            alt=""-->
<!--            v-if="!userInfo.userImg"-->
<!--          />-->
<!--          <img style="border-radius: 50%" :src="userInfo.userImg" alt="" v-else />-->
<!--        </a>-->
<!--      </h1>-->
    </header>

    <main v-if="limitData">
      <div class="vote-name">
        <!-- 您的姓名：{{personalName}} -->
        2024年度员工投票
      </div>
      <div class="vote-title">后勤(限选{{votingLimitC}}人)</div>
      <div class="main-vote">
        <label :for="'satisfie_a'+index" class="label-box" v-for="(item, index) in staffListC" :key="index">
          <span class="out-checkbox" :class="item.disable?'is-checked':''">
            <span class="inner-checkbox" :class="item.forbid?'inner-checkbox-disabled':''"></span>
            <input type="checkbox" class="input-checkbox" :id="'satisfie_a'+index" :disabled="item.forbid" v-model="item.disable" @change="handleChangeC(item, index)">
          </span>
          <span class="label-checkbox">{{item.staffName}}</span>
        </label>
      </div>

      <div class="vote-title">物业(限选{{votingLimitD}}人)</div>
      <div class="main-vote">
        <label :for="'satisfie_d'+index" class="label-box" v-for="(item, index) in staffListD" :key="index">
          <span class="out-checkbox" :class="item.disable?'is-checked':''">
            <span class="inner-checkbox" :class="item.forbid?'inner-checkbox-disabled':''"></span>
            <input type="checkbox" class="input-checkbox" :id="'satisfie_d'+index" :disabled="item.forbid" v-model="item.disable" @change="handleChangeD(item, index)">
          </span>
          <span class="label-checkbox">{{item.staffName}}</span>
        </label>
      </div>

<!--      <div class="vote-title">后勤(限选{{votingLimitC}}人)</div>-->
<!--      <div class="main-vote">-->
<!--        <label :for="'satisfie_c'+index" class="label-box" v-for="(item, index) in staffListC" :key="index">-->
<!--          <span class="out-checkbox" :class="item.disable?'is-checked':''">-->
<!--            <span class="inner-checkbox" :class="item.forbid?'inner-checkbox-disabled':''"></span>-->
<!--            <input type="checkbox" class="input-checkbox" :id="'satisfie_c'+index" :disabled="item.forbid" v-model="item.disable" @change="handleChangeC(item, index)">-->
<!--          </span>-->
<!--          <span class="label-checkbox">{{item.staffName}}</span>-->
<!--        </label>-->
<!--      </div>-->
      <div class="save-area">
        <button class="view-static" @click="viewStatic">查看统计</button>
        <button v-if="deptNum!=105"  class="save" @click="save">投票</button>
      </div>
    </main>
    <main class="empty" v-if="!limitData">
      <img src="@/assets/img/common/icon36.png" alt="">
      <div>您所登录的账号非内部员工账号<br/>请更换账号进行操作</div>
      <!-- <button class="out-login" @click="outLogin">退出登录</button> -->
    </main>
  </div>
</template>

<script lang="ts">
  import { defineComponent, inject, onMounted, ref} from "vue";
  import { useRouter, useRoute } from "vue-router";
  import Modal from "@/components/UI/Modal";
  import Toast from "@/components/UI/Toast";
  import axios from "@/api/axios";
  import $ from "jquery";
  import qs from "qs";
  export default defineComponent({
    name: "yearVote",
    components: {
    },
    setup() {
      const personalName = ref()
      const deptNum = ref()
      const staffListA = ref()
      const staffListB = ref()
      const staffListC = ref()
      const staffListD = ref()
      const votingLimitA = ref()
      const votingLimitB = ref()
      const votingLimitC = ref()
      const votingLimitD = ref()
      const limitData = ref(true)
      const router = useRouter();
      const route = useRoute();
      //  展示用户信息
      const userInfo = inject("userInfo") as any;


      onMounted(() => {
        const token = localStorage.getItem("token");
        if (!token) {
          router.push({
            name: "Login",
            query: {
              redirect: router.currentRoute.value.fullPath,
            },
          });
          return false;
        }
        $('.home-wapper').css('padding-top', '0')
        axios
                .post("/M/Staff/findListStaffByDeptNum")
                .then((res) => {
                  const data = res.data
                  if (data.success) {
                    data.obj.staffList101.forEach((item: any) => {
                      item.disable = false
                      item.forbid = false
                    });
                    data.obj.staffList102.forEach((item: any) => {
                      item.disable = false
                      item.forbid = false
                    });
                    data.obj.staffList103.forEach((item: any) => {
                      item.disable = false
                      item.forbid = false
                    });
                    data.obj.staffList104.forEach((item: any) => {
                      item.disable = false
                      item.forbid = false
                    });
                    staffListA.value = data.obj.staffList101
                    staffListB.value = data.obj.staffList102
                    staffListC.value = data.obj.staffList103
                    staffListD.value = data.obj.staffList104
                    votingLimitA.value = data.obj.votingLimit101
                    votingLimitB.value = data.obj.votingLimit102
                    votingLimitC.value = data.obj.votingLimit103
                    votingLimitD.value = data.obj.votingLimit104
                    personalName.value = data.obj.currentStaff.staffName
                    deptNum.value = data.obj.currentStaff.deptNum
                    // console.log(data.obj.currentStaff.staffName)
                    // console.log(data.obj.currentStaff.deptNum,5555555)

                  } else {
                    limitData.value = false
                    // Toast({
                    //   type: "error",
                    //   title: res.data.msg,
                    // });
                  }
                })
                .catch((err) => {
                  console.log(err)
                });

      })

      function handleChange(item: any, index: any) {
        let sum = 0
        staffListA.value.forEach((item: any) => {
          if (item.disable==true) {
            sum += 1
          }
        })
        if (sum>=5) {
          staffListA.value.forEach((item: any) => {
            if (item.disable!=true) {
              item.forbid = true
            }
          })
        } else {
          staffListA.value.forEach((item: any) => {
            item.forbid = false
          })
        }
      }

      function handleChangeA(item: any, index: any) {
        let sum = 0
        staffListA.value.forEach((item: any) => {
          if (item.disable==true) {
            sum += 1
          }
        })
        if (sum>=votingLimitA.value) {
          staffListA.value.forEach((item: any) => {
            if (item.disable!=true) {
              item.forbid = true
            }
          })
        } else {
          staffListA.value.forEach((item: any) => {
            item.forbid = false
          })
        }
      }
      function handleChangeB(item: any, index: any) {
        let sum = 0
        staffListB.value.forEach((item: any) => {
          if (item.disable==true) {
            sum += 1
          }
        })
        if (sum>=votingLimitB.value) {
          staffListB.value.forEach((item: any) => {
            if (item.disable!=true) {
              item.forbid = true
            }
          })
        } else {
          staffListB.value.forEach((item: any) => {
            item.forbid = false
          })
        }
      }
      function handleChangeC(item: any, index: any) {
        let sum = 0
        staffListC.value.forEach((item: any) => {
          if (item.disable==true) {
            sum += 1
          }
        })
        if (sum>=votingLimitC.value) {
          staffListC.value.forEach((item: any) => {
            if (item.disable!=true) {
              item.forbid = true
            }
          })
        } else {
          staffListC.value.forEach((item: any) => {
            item.forbid = false
          })
        }
      }

      function handleChangeD(item: any, index: any) {
        let sum = 0
        staffListD.value.forEach((item: any) => {
          if (item.disable==true) {
            sum += 1
          }
        })
        if (sum>=votingLimitD.value) {
          staffListD.value.forEach((item: any) => {
            if (item.disable!=true) {
              item.forbid = true
            }
          })
        } else {
          staffListD.value.forEach((item: any) => {
            item.forbid = false
          })
        }
      }
      function viewStatic() {
        const token = localStorage.getItem("token");
        if (!token) {
          Modal({
            title: "温馨提示",
            content: "登录后才可以进行投票！",
            onClose: () => {
              router.push({
                name: "Login",
                query: {
                  redirect: router.currentRoute.value.fullPath,
                },
              });
            },
          });
          return false;
        } else {
          router.push({
            name: "yearVoteView"
          });
        }
      }

      function save() {
        const staffData: any = []

        staffListA.value.forEach((item: any) => {
          if(item.disable == true) {
            staffData.push(item.id)
          }
        })

        staffListB.value.forEach((item: any) => {
          if(item.disable == true) {
            staffData.push(item.id)
          }
        })

        staffListC.value.forEach((item: any) => {
          if(item.disable == true) {
            staffData.push(item.id)
          }
        })

        staffListD.value.forEach((item: any) => {
          if(item.disable == true) {
            staffData.push(item.id)
          }
        })

        if (staffData.length==0) {
          Toast({
            type: "error",
            title: '请选择您要投票的人！',
          });
          return false
        }
        const data = qs.stringify({
          beVotedIds: staffData.join(',')
        });
        const token = localStorage.getItem("token");
        if (!token) {
          Modal({
            title: "温馨提示",
            content: "登录后才可以进行投票！",
            onClose: () => {
              router.push({
                name: "Login",
                query: {
                  redirect: router.currentRoute.value.fullPath,
                },
              });
            },
          });
          return false;
        } else {
          axios
                  .post("/M/Staff/doVoting", data, {
                    params: {
                      validate: true,
                    },
                  })
                  .then((res) => {
                    if (res.data.success) {
                      Toast({
                        type: "success",
                        title: res.data.msg,
                        onClose: () => {
                          router.push({
                            name: "yearVoteView"
                          });
                        },
                      });

                    } else {
                      Toast({
                        type: "error",
                        title: res.data.msg,
                      });
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                  });
        }
      }

      const clearUserInfo = inject("CLEAR_USERINFO") as () => void;
      function outLogin() {
        localStorage.removeItem("token");
        clearUserInfo();
        router.push({
          name: "Login",
          query: {
            redirect: router.currentRoute.value.fullPath,
          },
        });
      }

      return {
        userInfo,
        handleChange,
        handleChangeA,
        handleChangeB,
        handleChangeC,
        handleChangeD,
        save,
        viewStatic,
        staffListA,
        staffListB,
        staffListC,
        staffListD,
        votingLimitA,
        votingLimitB,
        votingLimitC,
        votingLimitD,
        limitData,
        outLogin,
        personalName,
        deptNum
      }
    },
  });
</script>

<style lang="scss" scoped>
  header {
    width: 375px;
    height: 55px;
    box-sizing: border-box;
    position: fixed;
    padding: 0 10px;
    padding-left: 20px;
    padding-right: 20px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    background-color: #fff;
    z-index: 10;
    border-bottom: 1px solid #eee;
  }
  header .title {
    width: 8rem;
    // font-size: 18px;
    font-size: 16px;
    text-align: left;
  }
  .logo {
    width: 27px;
    height: 27px;
    margin: 0;
  }
  .logo a {
    display: block;
    width: 27px;
    height: 27px;
  }
  .logo img {
    width: 27px;
    height: 27px;
    display: block;
  }

  main {
    margin-top: 55px;
    padding: 20px;
    padding-top: 30px;
  }

  .main-vote {
    display: flex;
    // justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .main-vote label {
    width: 33.3%;
    padding: 10px 0;
  }


  .label-box {
    color: #606266;
    font-weight: 500;
    font-size: .37333rem;
    position: relative;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
  }


  .out-checkbox {
    position: relative;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    outline: none;
    line-height: 1;
    vertical-align: middle;
  }

  .inner-checkbox {
    display: inline-block;
    position: relative;
    border: .02667rem solid #dcdfe6;
    border-radius: .05333rem;
    box-sizing: border-box;
    width: .53333rem;
    height: .53333rem;
    background-color: #fff;
    z-index: 1;
    -webkit-transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
    transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
  }

  .inner-checkbox-disabled {
    background-color: #dcdfe6;
  }

  .inner-checkbox::after {
    box-sizing: content-box;
    content: "";
    border: .02667rem solid #fff;
    border-left: 0;
    border-top: 0;
    height: .26667rem;
    left: .16rem;
    position: absolute;
    top: .02667rem;
    -webkit-transform: rotate(
                    45deg
    ) scaleY(0);
    -ms-transform: rotate(45deg) scaleY(0);
    transform: rotate(
                    45deg
    ) scaleY(0);
    width: .13333rem;
    -webkit-transition: -webkit-transform .15s ease-in .05s;
    transition: -webkit-transform .15s ease-in .05s;
    transition: transform .15s ease-in .05s;
    transition: transform .15s ease-in .05s,-webkit-transform .15s ease-in .05s;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
  }

  .input-checkbox {
    opacity: 0;
    outline: none;
    position: absolute;
    margin: 0;
    width: 0;
    height: 0;
    z-index: -1;
  }

  .out-checkbox.is-checked .inner-checkbox {
    background-color: #df0024;
    border-color: #df0024;
  }

  .out-checkbox.is-checked .inner-checkbox:after {
    transform: rotate(45deg) scaleY(1);
  }

  .label-checkbox {
    display: inline-block;
    padding-left: .26667rem;
    line-height: .50667rem;
    font-size: .37333rem;
  }

  .form-item-textarea {
    margin-top: 25px;
  }

  .form-item-textarea textarea {
    width: 100%;
    // text-align: right;
    // outline: none;
    // border: none;
    border: 1px solid #eee;
    padding-right: 10px;
    box-sizing: border-box;
    font-family: Arial;
    margin-top: 10px;
    padding: 10px;
    outline: none;
  }

  .save-area {
    width: 100%;
    height: 1.2rem;
    position: relative;
    margin-top: 70px;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
  }

  .view-static {
    display: inline-block;
    width: 40%;
    height: 1.2rem;
    background-color: #999;
    box-shadow: 0 0.13333rem 0.26667rem 0 rgb(0 0 0 / 30%);
    border-radius: 0.1rem;
    line-height: 1.2rem;
    text-align: center;
    color: #ffffff !important;
    font-size: 0.4rem;
    border: none;
    outline: none;
  }

  .save {
    // margin-left: 10%;
    width: 40%;
    height: 1.2rem;
    background-color: #d64b4b;
    box-shadow: 0 0.13333rem 0.26667rem 0 rgb(0 0 0 / 30%);
    border-radius: .1rem;
    line-height: 1.2rem;
    text-align: center;
    color: #fff;
    border: none;
    outline: none;
    font-size: .4rem;
  }

  .vote-title {
    font-size: 16px;
    color: #606266;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 20px;
  }

  .vote-name {
    font-size: 18px;
    // font-size: 16px;
    color: #606266;
    font-weight: bold;
    // border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    // margin-bottom: 20px;
    text-align: center;
    position: relative;
    top: -12px;
  }

  .empty {
    padding-top: 50%;
    text-align: center;
  }

  .empty img {
    width: 35%;
  }

  .empty div {
    color: #9f9f9f;
    font-size: 0.3733rem;
    margin-top: 0.266rem;
  }

  .out-login {
    width: 100%;
    display: block;
    margin: 30px auto;
    height: 45px;
    background-color: #d64b4b;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
    border-radius: 3.75px;
    line-height: 45px;
    text-align: center;
    color: #ffffff;
    border: none;
    outline: none;
    font-size: 15px;
  }
  .back-login {
    width: 100%;
    display: block;
    margin: 30px auto;
    height: 30px;
    background-color: #d64b4b;
    // box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
    border-radius: 3.75px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    border: none;
    outline: none;
    font-size: 15px;
  }
</style>